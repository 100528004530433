export default class {
  /**
   * @param {Object} schedule
   */
  constructor(schedule) {
    this.modeList = [
      'month',
      'dayOfMonth',
      'dayOfWeek',
      'hour',
      'minute',
    ];
    this.modeList.forEach((mode) => {
      this[mode] = schedule[mode];
    });
  }

  static getNumberWithOrdinal(n) {
    const s = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  static getOxfordConjunction(array, conjunction = 'and', ifEmpty = '') {
    const l = array.length;
    if (!l) return ifEmpty;
    if (l < 2) return array[0];
    if (l < 3) return array.join(` ${conjunction} `);
    const lastElement = array.pop();
    return `${array.join(', ')} ${conjunction} ${lastElement}`;
  }

  getRunWhenLabel() {
    const toggleList = {};
    const matchToggleList = {};
    const valueList = {};
    const label = [];
    const self = this.constructor;
    this.modeList.forEach((mode) => {
      matchToggleList[mode] = self.matchToggleList(mode, this[mode]);
      toggleList[mode] = self.modeToggleList(mode);
      valueList[mode] = self.modeValueList(mode);

      if (matchToggleList[mode] === false) {
        const modeValueList = [];
        let additional = 0;
        this[mode].forEach((value) => {
          if (modeValueList.length > 2) additional += 1;
          else modeValueList.push(self.modeValueLabel(mode, value));
        });
        if (additional > 0) {
          additional += 1;
          modeValueList.shift();
          modeValueList.push(`${additional} more`);
        }
        label.push(`${self.modeLabel(mode)} (${self.getOxfordConjunction(modeValueList)})`);
      } else if (matchToggleList[mode] > 0 || matchToggleList[mode] === 'weekday') {
        label.push(self.modeToggleLabel(mode, matchToggleList[mode]));
      }
    });

    if (label.length === 0) label.push('Every minute');

    return self.getOxfordConjunction(label);
  }

  static matchToggleList(mode, value) {
    const jsonValue = JSON.stringify([...value].sort());
    let toggle = false;
    if (jsonValue === JSON.stringify([...this.modeValueList(mode)].sort())) {
      toggle = mode === 'dayOfWeek' ? 'every' : 0;
    } else if (mode === 'dayOfWeek') {
      const check = [...this.modeValueList(mode)];
      check.pop();
      check.pop();
      if (jsonValue === JSON.stringify(check.sort())) {
        toggle = 'weekday';
      }
    } else {
      const toggleOptions = [...this.modeToggleList(mode)];
      toggleOptions.shift();
      toggleOptions.forEach((t) => {
        if (jsonValue === JSON.stringify(
          [...this.modeValueList(mode)].filter((v, i) => i % t === 0)
            .sort(),
        )) {
          toggle = t;
        }
      });
    }
    return toggle;
  }

  static modeLabel(mode, plural = true) {
    switch (mode) {
      case 'minute': return plural ? 'Minutes' : 'Minute';
      case 'hour': return plural ? 'Hours' : 'Hour';
      case 'dayOfWeek': return plural ? 'Days of Week' : 'Day of Week';
      case 'dayOfMonth': return plural ? 'Days of Month' : 'Day of Month';
      case 'month': return plural ? 'Months' : 'Month';
      default: return mode;
    }
  }

  static modeToggleLabel(mode, toggle) {
    switch (mode) {
      case 'minute':
        if (toggle === 0) return 'Every minute';
        if (toggle === 2) return 'Every other minute';
        return `Every ${toggle} minutes`;
      case 'hour':
        if (toggle === 0) return 'Every hour';
        if (toggle === 2) return 'Every other hour';
        return `Every ${toggle} hours`;
      case 'dayOfWeek':
        if (toggle === 0) return 'Everyday';
        return 'Every weekday';
      case 'dayOfMonth':
        if (toggle === 0) return 'Every day';
        if (toggle === 2) return 'Every other day';
        return `Every ${this.getNumberWithOrdinal(toggle)} day`;
      case 'month':
        if (toggle === 0) return 'Every month';
        if (toggle === 2) return 'Every other month';
        return `Every ${this.getNumberWithOrdinal(toggle)} month`;
      default:
        return `${mode} - ${toggle}`;
    }
  }

  static modeToggleList(mode) {
    switch (mode) {
      case 'minute': return [0, 2, 5, 10, 15, 30];
      case 'hour': return [0, 2, 4, 6, 12];
      case 'dayOfWeek': return ['every', 'weekday'];
      case 'dayOfMonth': return [0, 2, 3, 5, 10];
      case 'month': return [0, 2, 3, 4, 6];
      default: return mode;
    }
  }

  static modeValueLabel(mode, value) {
    switch (mode) {
      case 'minute': return `:${value < 10 ? '0' : ''}${value}`;
      case 'hour': return `${value < 10 ? '0' : ''}${value}:xx`;
      case 'dayOfWeek':
        switch (value) {
          case 0: return 'Sunday';
          case 1: return 'Monday';
          case 2: return 'Tuesday';
          case 3: return 'Wednesday';
          case 4: return 'Thursday';
          case 5: return 'Friday';
          case 6: return 'Saturday';
          default: return 'Unknown';
        }
      case 'dayOfMonth': return this.getNumberWithOrdinal(value);
      case 'month':
        switch (value) {
          case 1: return 'January';
          case 2: return 'February';
          case 3: return 'March';
          case 4: return 'April';
          case 5: return 'May';
          case 6: return 'June';
          case 7: return 'July';
          case 8: return 'August';
          case 9: return 'September';
          case 10: return 'October';
          case 11: return 'November';
          case 12: return 'December';
          default: return 'Unknown';
        }
      default: return value;
    }
  }

  static modeValueList(mode) {
    switch (mode) {
      case 'minute': return Array.from(Array(60).keys());
      case 'hour': return Array.from(Array(24).keys());
      case 'dayOfWeek': return Array.from(Array(7).keys());
      case 'dayOfMonth': return Array.from(Array(31).keys()).map(m => m + 1);
      case 'month': return Array.from(Array(12).keys()).map(m => m + 1);
      default: return [];
    }
  }
}
