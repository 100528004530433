<template>
  <div class="sGrid">
    <h1>{{ label }}</h1>
    <div>
      <div
        v-for="toggleOption in toggleOptions"
        :key="toggleOption"
      >
        <v-switch
          :value="toggle === toggleOption"
          :label="toggleText(toggleOption)"
          hide-details
          @change="setToggle(toggleOption)"
        />
      </div>
    </div>
    <div class="sCheck">
      <div
        v-for="(row, rowIndex) in valueRows"
        :key="`row${rowIndex}`"
      >
        <span
          v-for="v in row"
          :key="`v${v}`"
          :style="checkStyle"
        >
          <label>
            {{ checkLabel(v) }}
            <input
              v-model="value"
              type="checkbox"
              :value="v"
            >
            <span class="check" />
          </label>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Schedule from '../../../../model/runner/Schedule';

export default {
  name: 'RunnerJobScheduleSelect',
  props: {
    mode: String,
    load: Array,
  },
  data() {
    return {
      toggle: false,
      value: [],
    };
  },
  computed: {
    checkStyle() {
      switch (this.mode) {
        case 'minute':
        case 'hour':
        case 'dayOfMonth':
          return { width: '70px' };
        case 'dayOfWeek':
        case 'month':
          return { width: '120px' };
        default: return {};
      }
    },
    label() {
      return Schedule.modeLabel(this.mode);
    },
    toggleOptions() {
      return Schedule.modeToggleList(this.mode);
    },
    valueList() {
      return Schedule.modeValueList(this.mode);
    },
    valueRows() {
      const rows = [];
      let perRow = 99;
      switch (this.mode) {
        case 'minute': perRow = 10; break;
        case 'hour': perRow = 6; break;
        case 'dayOfWeek': perRow = 5; break;
        case 'dayOfMonth': perRow = 10; break;
        case 'month': perRow = 6; break;
        default: break;
      }
      for (let i = 0; i < this.valueList.length; i += perRow) {
        rows.push(this.valueList.slice(i, i + perRow));
      }
      return rows;
    },
  },
  watch: {
    load: {
      handler: 'loadValue',
      immediate: true,
    },
    value: {
      handler: 'toggleSet',
      immediate: true,
    },
  },
  methods: {
    checkLabel(value) {
      return Schedule.modeValueLabel(this.mode, value);
    },
    loadValue() {
      this.value = this.load;
      this.toggleSet();
    },
    setToggle(mode) {
      if (this.toggle === mode) {
        this.value = [];
      } else if (this.mode === 'dayOfWeek') {
        this.value = [...this.valueList];
        if (mode === 'weekday') {
          this.value.pop();
          this.value.pop();
        }
      } else {
        this.value = mode === 0
          ? this.valueList
          : this.valueList.filter((value, index) => index % mode === 0);
      }
    },
    toggleSet() {
      this.toggle = Schedule.matchToggleList(this.mode, this.value);
      this.$emit('value', this.value);
    },
    toggleText(toggle) {
      return Schedule.modeToggleLabel(this.mode, toggle);
    },
  },
};
</script>

<style scoped lang="sass">
@import "../../../../scss/shepherd-vars.scss"
.sGrid
  display: grid
  grid-template-columns: 140px 200px auto
  margin-bottom: 20px
  h1
    color: $colorOrange
    font-size: 1em
    text-align: right
    padding-right: 20px
  .sCheck
    div
      margin-bottom: 5px
      span
        display: inline-block
        label
          display: inline-block
          position: relative
          padding-left: 25px
          cursor: pointer
          input
            position: absolute
            opacity: 0
            cursor: pointer
            height: 0
            width: 0
          .check
            position: absolute
            top: 0
            left: 0
            height: 15px
            width: 15px
            background-color: #eee
            &:after
              content: ""
              position: absolute
              display: none
              left: 4px
              top: 1px
              width: 7px
              height: 10px
              border: solid $colorOrange
              border-width: 0 3px 3px 0
              -webkit-transform: rotate(45deg)
              -ms-transform: rotate(45deg)
              transform: rotate(45deg)
        &:hover input ~ .check
          background-color: #ddd
        input:checked ~ .check
          &:after
            display: block
</style>
