export default class {
  /**
   * @param {Array} month
   * @param {Array} dayOfMonth
   * @param {Array} dayOfWeek
   * @param {Array} hour
   * @param {Array} minute
   */
  constructor(month, dayOfMonth, dayOfWeek, hour, minute) {
    this.month = month;
    this.dayOfMonth = dayOfMonth;
    this.dayOfWeek = dayOfWeek;
    this.hour = hour;
    this.minute = minute;
  }

  nextMatch() {
    // Get current day as starting point
    const currentDay = new Date();
    currentDay.setUTCMinutes(currentDay.getUTCMinutes() + 1); // Skip current minute
    currentDay.setUTCSeconds(0);
    currentDay.setUTCMilliseconds(0);

    let matchFound = null;
    let dayCounter = 0;
    const maxCheckDays = 365;
    while (matchFound === null) {
      // Check if schedule matches day
      matchFound = this.matchDay(new Date(currentDay.getTime())) || null;

      if (!matchFound) {
        // Add another day and reset time on all subsequent days
        if (currentDay.getUTCHours() > 0) currentDay.setUTCHours(0);
        if (currentDay.getUTCMinutes() > 0) currentDay.setUTCMinutes(0);
        currentDay.setUTCDate(currentDay.getUTCDate() + 1);

        // Break loop if no match found
        dayCounter += 1;
        if (dayCounter > maxCheckDays) matchFound = false;
      }
    }

    return matchFound;
  }

  matchDay(checkDay) {
    // Check for matching month
    if (this.month.indexOf(checkDay.getUTCMonth() + 1) < 0) {
      return false;
    }

    // Check for matching day of month
    if (this.dayOfMonth.indexOf(checkDay.getUTCDate()) < 0) {
      return false;
    }

    // Check for matching day of week
    if (this.dayOfWeek.indexOf(checkDay.getUTCDay()) < 0) {
      return false;
    }

    // Go through hours to check
    let checkMinuteStart = null;
    for (let checkHour = checkDay.getUTCHours(); checkHour < 24; checkHour += 1) {
      if (this.hour.indexOf(checkHour) > -1) {
        // Go through minutes to check
        if (checkMinuteStart === null) checkMinuteStart = checkDay.getUTCMinutes();
        else checkMinuteStart = 0;
        for (
          let checkMinute = checkMinuteStart;
          checkMinute < 60;
          checkMinute += 1
        ) {
          if (this.minute.indexOf(checkMinute) > -1) {
            checkDay.setUTCHours(checkHour);
            checkDay.setUTCMinutes(checkMinute);
            return checkDay;
          }
        }
      } else if (checkMinuteStart === null) {
        checkMinuteStart = 0;
      }
    }
    return false;
  }
}
