<template>
  <div>
    <v-card>
      <v-card-title>
        Runner Job List
        <v-spacer/>
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        />
        <v-spacer/>
        <v-switch
          v-model="hideDisabled"
          dense
          hide-details
          label="Hide Disabled"
        />
        <v-spacer/>
        <v-btn
          color="green"
          dark
          :to="{name: 'runner-job-upsert'}"
        >
          <v-icon class="mr-2" small>fal fa-plus</v-icon>
          Add Job
        </v-btn>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="tableData"
        :search="search"
      >
        <template v-slot:item.view="{ item }">
          <v-btn
            fab
            dark
            small
            color="primary"
            :to="{
                  name: 'runner-job-detail',
                  params: { jobID: item.id }
                }">
            <v-icon dark>fas fa-eye</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.delete="{ item }">
          <v-btn fab dark small color="primary" @click="deleteJob = item">
            <v-icon dark>far fa-trash-alt</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="deleteJobVisible"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">Delete Job</v-card-title>

        <v-card-text>
          Are you sure you want to delete {{ deleteJob.name }}?
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="green darken-1"
            text
            @click="deleteJob = false"
          >
            Cancel
          </v-btn>

          <v-btn
            color="red darken-1"
            text
            @click="actionDeleteJob"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { db } from '../../../../firestore';
import KubernetesImage from '../../../../model/kubernetes/Image';
import Job from '../../../../model/runner/Job';

const kubernetesImage = new KubernetesImage();

export default {
  name: 'Home',
  computed: {
    tableData() {
      return this.job.map(j => {
        return {
          ...j,
          imageNice: `${this.imageNameNice(j.image)}/${j.command}`,
          updated: j.updated ? moment(new Date(j.updated.seconds * 1000)).fromNow() : 'N/A',
          nextRun: j.nextRun
            ? moment(new Date(j.nextRun.seconds * 1000)).fromNow()
            : 'Not Scheduled',
          status: this.statusName(j.status),
          id: j.id,
        }
      }).filter(j => j.status !== 'Disabled' || !this.hideDisabled);
    }
  },
  data() {
    return {
      deleteJob: false,
      deleteJobVisible: false,
      headers: [
        {
          text: 'View',
          align: 'left',
          value: 'view',
        },
        {
          text: 'Job Name',
          align: 'left',
          value: 'name',
        },
        {
          text: 'Image',
          align: 'left',
          value: 'imageNice',
        },
        {
          text: 'Updated',
          align: 'left',
          value: 'updated',
        },
        {
          text: 'Next Run',
          align: 'left',
          value: 'nextRun',
        },
        {
          text: 'Status',
          align: 'left',
          value: 'status',
        },
        {
          text: 'Delete',
          align: 'left',
          value: 'delete',
        },
      ],
      hideDisabled: true,
      job: [],
      search: ''
    };
  },
  methods: {
    actionDeleteJob() {
      db.collection('shepherd-job').doc(this.deleteJob.id).delete();
      this.deleteJob = false;
    },
    imageNameNice(image) {
      return kubernetesImage.getJobImageNiceName(image);
    },
    statusName(status) {
      return Job.statusCodeList()[status] || 'Unknown';
    },
  },
  firestore: {
    job: db.collection('shepherd-job'),
  },
  watch: {
    deleteJob() {
      this.deleteJobVisible = this.deleteJob !== false;
    },
  },
};
</script>
